import React from 'react'
import PropTypes from 'prop-types'

const Embed = ({
  id,
  width,
  height,
  start,
  loop,
  delayMs,
  ...props
}) => {
  start = start || false
  loop = loop || false
  delayMs = delayMs || 3000
  // width = width || 960
  // height = height || 569
  return (
    <iframe
      {...props}
      src={`https://docs.google.com/presentation/d/e/${id}/embed?start=${start}&loop=${loop}&delayms=${delayMs}`}
      width={width}
      height={height}
      frameBorder="0"
      allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"
    />
  )
}

Embed.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  start: PropTypes.bool,
  loop: PropTypes.bool,
  delayMs: PropTypes.number
}

export default Embed
