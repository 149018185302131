import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import theme from '../../config/theme'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import Helmet from 'react-helmet'
import GSlidesEmbed from '@components/GSlidesEmbed'
import YTEmbed from '@components/YTEmbed'
import PresentationsIcons from '@components/PresentationsIcons'
import { graphql } from "gatsby";

const Wrapper = styled.div`
  margin: 2rem;
`

const _StyledGSE = ({ className, ...props }) =>
  <div className={className}>
    <GSlidesEmbed {...props} title="Slide deck" />
  </div>
_StyledGSE.propTypes = {className:PropTypes.string}

const StyledGSE = styled(_StyledGSE)`
  position: relative;
  overflow: hidden;
  /* 16:9 slide aspect ratio + 29px bottom controls bar */
  padding-top: calc(100% * 9/16 + 29px);
  margin-bottom: 1.5rem;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`

const _StyledYTE = ({ className, ...props }) =>
  <div className={className}>
    <YTEmbed {...props} title="Video" />
  </div>
_StyledYTE.propTypes = {className:PropTypes.string}

const StyledYTE = styled(_StyledYTE)`
  position: relative;
  overflow: hidden;
  padding-top: calc(100% * 9/16);
  margin-bottom: 1.5rem;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
`

const StyledEmbed = styled.embed`
  width: 100%;
  height: calc(100vh - 2rem);
  min-height: 10rem;
  max-height: 100vw;
`

const Page = ({
  data: {
    presentationsYaml: {
      title,
      eventTitle,
      videoId,
      slideDeck,
      paperUrl
    }
  }
}) =>
  <Layout title={title}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Hero>
      <h1>{title}</h1>
      {eventTitle &&
        <h3>Presented at {eventTitle}</h3>
      }
      <PresentationsIcons color={theme.colors.light.color} { ...{videoId, slideDeck, paperUrl} } />
    </Hero>
    <Wrapper>
      {videoId &&
        <StyledYTE videoId={videoId} />
      }
      {slideDeck &&
        <StyledGSE id={slideDeck} />
      }
      {paperUrl &&
        <StyledEmbed src={paperUrl} />
      }
    </Wrapper>
  </Layout>

Page.propTypes = {
  data: PropTypes.shape({
    presentationsYaml: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      videoId: PropTypes.string,
      slideDeck: PropTypes.string,
      paperUrl: PropTypes.string,
    })
  })
}

export default Page

export const PageQuery = graphql`
  query (
    $slug: String!
  ) {
    presentationsYaml (
      slug: {eq: $slug}
    ) {
      title
      date
      eventTitle
      videoId
      slideDeck
      paperUrl
    }
  }
`
