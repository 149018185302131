import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const RealYTEmbed = ({
  videoId,
  ...props
}) =>
  <iframe
    {...props}
    src={`https://youtube.com/embed/${videoId}`}
    frameBorder="0"
    allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"
  />

RealYTEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
}

const LazyYTContainer = styled.div`
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & > .placeholder {
    cursor: pointer;

    :focus {
      outline: 1px dotted gray;
    }
  }
`

const YTEmbed = ({
  videoId,
  title,
  lazy,
  thumbnailQuality,
  ...props
}) => {
  const [loaded, setLoaded] = useState(false)
  const lazyContainerEl = useRef(null)
  const load = () => setLoaded(true)
  useEffect(() => {
    if (lazy && !loaded) {
      let isObserving = true
      const observer = new IntersectionObserver(
        intersections => {
          if (intersections.length < 1) return
          if (intersections[0].isIntersecting) {
            load()
            observer.unobserve(lazyContainerEl.current)
            isObserving = false
          }
        },
        {
          rootMargin: "50%"
        }
      )
      observer.observe(lazyContainerEl.current)
      return () => {
        if (isObserving) {
          observer.unobserve(lazyContainerEl.current)
        }
      }
    }
  }, [lazy])
  if (!lazy) {
    if (!loaded) {
      setLoaded(true)
    }
    return <RealYTEmbed {...({ videoId, title, ...props })} />
  } else {
    thumbnailQuality = thumbnailQuality || 'maxres'
    return (
      <LazyYTContainer {...props} ref={lazyContainerEl}>
        {loaded ? (
          <RealYTEmbed {...({ videoId, title })} />
        ) : (
          <img
            className="placeholder"
            src={`https://img.youtube.com/vi/${videoId}/${thumbnailQuality}default.jpg`}
            onClick={load}
            tabIndex="0"
            onKeyDown={(ev) => ev.key === 'Enter' && load()} /* a11y */
          />
        )}
      </LazyYTContainer>
    )
  }
}

YTEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  lazy: PropTypes.bool.isRequired,
  thumbnailQuality: PropTypes.oneOf(['sd', 'mq', 'hq', 'maxres']),
  title: PropTypes.string, // iframe title attribute
}

export default YTEmbed
